import React from "react";

const Loading = (props) => {
	return (
		<div className={`loadingPage + ${props.color}`}>
			<div className="small-loading"></div>
            <p>Hello, we are getting the songs ready</p>
		</div>
	);
};

export default Loading;

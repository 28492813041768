import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import {
    createBrowserRouter,
    RouterProvider,
  } from "react-router-dom";
  import ReactGA from 'react-ga4';

import App from './App';
import Error from "./Routes/Error"
ReactGA.initialize('G-1F3BLFQBY7');
const router = createBrowserRouter([
    {
      path: "/",
      element: <App />,
      errorElement:<Error />
    }
  ]);

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(<RouterProvider router={router} />)
